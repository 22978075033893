import React, { FC, lazy } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { Loader, MainScreenProps, ModalContainerProps } from 'components';
import ModalPageWrapper from './modal.styled';

const MainScreen = lazy(() => import('components/main-screen/main-screen'));
const ModalContainer = lazy(() => import('components/modal-container/modal-container'));
const ThemeController = lazy(() => import('controllers/theme/theme'));

interface ModalPageProps {}

const ModalPage: FC<ModalPageProps> = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const backgroundPath = location.state?.background;
  const backToPath = backgroundPath || ROUTE_NAME_MAP.HOME_INDEX;
  const onModalClose = () => navigate(backToPath);

  const modalProps: ModalContainerProps = {
    type: 'sub',
    open: true,
    onClose: onModalClose,
  };

  let containerShouldBeModal = Boolean(backgroundPath);
  let screenProps: MainScreenProps = {};
  let content: JSX.Element | undefined;

  switch (params.modal) {
    case 'theme':
      modalProps.headerProps = {
        title: 'Theme',
        onClick: onModalClose,
        returnTo: backToPath,
      };
      screenProps = {
        title: 'Theme',
        description:
          'Personalize your font size, color, and background settings. Your customizations will be saved locally on your device.',
      };
      content = <ThemeController />;
      break;
    default:
      containerShouldBeModal = false;
      screenProps = {
        title: 'Oops!',
        description:
          "Well this is embarrassing. It's not me, it's you. The modal you're seeking seems to be taking a break from existence.",
        helmetTitle: '404 / Portfolio',
        hideLinks: ['theme'],
      };
      break;
  }

  const container = containerShouldBeModal ? (
    <Loader spinner={{ thickness: 10 }} size="xjumbo" scale={2} background="modal">
      <ModalContainer {...modalProps}>{content}</ModalContainer>
    </Loader>
  ) : (
    <MainScreen {...screenProps}>{content}</MainScreen>
  );

  return (
    <ModalPageWrapper
      className="ui-display-flex ui-items-center ui-justify-center ui-flex-column"
      data-testid="modal"
    >
      <Loader
        spinner={{
          thickness: 10,
        }}
        size="xjumbo"
        scale={2}
        background="modal"
      >
        {container}
      </Loader>
    </ModalPageWrapper>
  );
};

export default ModalPage;
