import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from 'styled-components';
import {
  AppSize,
  ApplicationTheme,
  FONT_SIZES,
  GLOBAL_COLORS,
  ICON_SIZES,
  THEME_COLORS,
  THEME_MODE_COLORS,
} from 'constants/application-theme';
import React, { ReactNode } from 'react';
import { useApplicationTheme } from 'hooks';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';

/**
 * Global css styling
 */
const GlobalStyle = createGlobalStyle<{}>`
  :root {
    /* Color variables */
    --color-theme: ${(p) => p.theme.currentThemeColor};

    --color-mosiac: ${THEME_COLORS.mosiac};
    --color-verdant: ${THEME_COLORS.verdant};
    --color-ignis: ${THEME_COLORS.ignis};
    --color-champion: ${THEME_COLORS.champion};
    --color-amethyst: ${THEME_COLORS.amethyst};
    --color-fiery: ${THEME_COLORS.fiery};

    --color-error: ${GLOBAL_COLORS.error};
    --color-red: ${GLOBAL_COLORS.red};
    --color-warn: ${GLOBAL_COLORS.warn};
    --color-success: ${GLOBAL_COLORS.success};
    --color-gold: ${GLOBAL_COLORS.gold};
    --color-white: ${GLOBAL_COLORS.white};
    --color-black: ${GLOBAL_COLORS.black};

    --color-alpha: ${(p) => p.theme.alpha};
    --color-bravo: ${(p) => p.theme.bravo};
    --color-medium: ${(p) => p.theme.medium};
    --color-charlie: ${(p) => p.theme.charlie};
    --color-card: ${(p) => p.theme.card};

    --color-modal: rgba(0, 0, 0, 0.5);

    /* Font size variables */
    --font-xsmall: ${(p) => FONT_SIZES[p.theme.activeAppSize as AppSize].xsmall};
    --font-small: ${(p) => FONT_SIZES[p.theme.activeAppSize as AppSize].small};
    --font-normal: ${(p) => FONT_SIZES[p.theme.activeAppSize as AppSize].normal};
    --font-large: ${(p) => FONT_SIZES[p.theme.activeAppSize as AppSize].large};
    --font-xlarge: ${(p) => FONT_SIZES[p.theme.activeAppSize as AppSize].xlarge};
    --font-jumbo: ${(p) => FONT_SIZES[p.theme.activeAppSize as AppSize].jumbo};
    --font-xjumbo: ${(p) => FONT_SIZES[p.theme.activeAppSize as AppSize].xjumbo};

    /* Icon size variables */
    --icon-xsmall: ${(p) => ICON_SIZES[p.theme.activeAppSize as AppSize].xsmall};
    --icon-small: ${(p) => ICON_SIZES[p.theme.activeAppSize as AppSize].small};
    --icon-normal: ${(p) => ICON_SIZES[p.theme.activeAppSize as AppSize].normal};
    --icon-large: ${(p) => ICON_SIZES[p.theme.activeAppSize as AppSize].large};
    --icon-xlarge: ${(p) => ICON_SIZES[p.theme.activeAppSize as AppSize].xlarge};
    --icon-jumbo: ${(p) => ICON_SIZES[p.theme.activeAppSize as AppSize].jumbo};
    --icon-xjumbo: ${(p) => ICON_SIZES[p.theme.activeAppSize as AppSize].xjumbo};

    /* Spacing variables */
    --item-size-0: 0px;
    --item-size-1: 4px;
    --item-size-2: 8px;
    --item-size-3: 10px;
    --item-size-4: 14px;
    --item-size-5: 18px;
    --item-size-6: 22px;
    --item-size-7: 24px;
    --item-size-8: 28px;
    --item-size-9: 34px;

    --opacity-disabled: 0.7;
  }
  /* ATOMICS */
  /* Font classes */
  .ui-font-xsmall { font-size: var(--font-xsmall); }
  .ui-font-small { font-size: var(--font-small); }
  .ui-font-normal { font-size: var(--font-normal); }
  .ui-font-large { font-size: var(--font-large); }
  .ui-font-xlarge { font-size: var(--font-xlarge); }
  .ui-font-jumbo { font-size: var(--font-jumbo); }
  .ui-font-xjumbo { font-size: var(--font-xjumbo); }

  /* Icon classes */
  .ui-height-xsmall { height: var(--icon-xsmall); }
  .ui-height-small { height: var(--icon-small); }
  .ui-height-normal { height: var(--icon-normal); }
  .ui-height-large { height: var(--icon-large); }
  .ui-height-xlarge { height: var(--icon-xlarge); }
  .ui-height-jumbo { height: var(--icon-jumbo); }
  .ui-height-xjumbo { height: var(--icon-xjumbo); }

  .ui-width-xsmall { width: var(--icon-xsmall); }
  .ui-width-small { width: var(--icon-small); }
  .ui-width-normal { width: var(--icon-normal); }
  .ui-width-large { width: var(--icon-large); }
  .ui-width-xlarge { width: var(--icon-xlarge); }
  .ui-width-jumbo { width: var(--icon-jumbo); }
  .ui-width-xjumbo { width: var(--icon-xjumbo); }

  /* Color classes */
  .ui-color-theme { color: var(--color-theme); }
  .ui-bg-color-theme { background-color: var(--color-theme); }
  .ui-border-color-theme { border-color: var(--color-theme); }

  .ui-color-mosiac { color: var(--color-mosiac); }
  .ui-bg-color-mosiac { background-color: var(--color-mosiac); }
  .ui-border-color-mosiac { border-color: var(--color-mosiac); }

  .ui-color-verdant { color: var(--color-verdant); }
  .ui-bg-color-verdant { background-color: var(--color-verdant); }
  .ui-border-color-verdant { border-color: var(--color-verdant); }

  .ui-color-ignis { color: var(--color-ignis); }
  .ui-bg-color-ignis { background-color: var(--color-ignis); }
  .ui-border-color-ignis { border-color: var(--color-ignis); }

  .ui-color-champion { color: var(--color-champion); }
  .ui-bg-color-champion { background-color: var(--color-champion); }
  .ui-border-color-champion { border-color: var(--color-champion); }

  .ui-color-amethyst { color: var(--color-amethyst); }
  .ui-bg-color-amethyst { background-color: var(--color-amethyst); }
  .ui-border-color-amethyst { border-color: var(--color-amethyst); }

  .ui-color-fiery { color: var(--color-fiery); }
  .ui-bg-color-fiery { background-color: var(--color-fiery); }
  .ui-border-color-fiery { border-color: var(--color-fiery); }

  .ui-color-error { color: var(--color-error); }
  .ui-bg-color-error { background-color: var(--color-error); }
  .ui-border-color-error { border-color: var(--color-error); }

  .ui-color-red { color: var(--color-red); }
  .ui-bg-color-red { background-color: var(--color-red); }
  .ui-border-color-red { border-color: var(--color-red); }

  .ui-color-warn { color: var(--color-warn); }
  .ui-bg-color-warn { background-color: var(--color-warn); }
  .ui-border-color-warn { border-color: var(--color-warn); }

  .ui-color-success { color: var(--color-success); }
  .ui-bg-color-success { background-color: var(--color-success); }
  .ui-border-color-success { border-color: var(--color-success); }

  .ui-color-gold { color: var(--color-gold); }
  .ui-bg-color-gold { background-color: var(--color-gold); }
  .ui-border-color-gold { border-color: var(--color-gold); }

  .ui-color-white { color: var(--color-white); }
  .ui-bg-color-white { background-color: var(--color-white); }
  .ui-border-color-white { border-color: var(--color-white); }

  .ui-color-black { color: var(--color-black); }
  .ui-bg-color-black { background-color: var(--color-black); }
  .ui-border-color-black { border-color: var(--color-black); }

  .ui-color-alpha { color: var(--color-alpha); }
  .ui-bg-color-alpha { background-color: var(--color-alpha); }
  .ui-border-color-alpha { border-color: var(--color-alpha); }

  .ui-color-bravo { color: var(--color-bravo); }
  .ui-bg-color-bravo { background-color: var(--color-bravo); }
  .ui-border-color-bravo { border-color: var(--color-bravo); }

  .ui-color-medium { color: var(--color-medium); }
  .ui-bg-color-medium { background-color: var(--color-medium); }
  .ui-border-color-medium { border-color: var(--color-medium); }

  .ui-color-charlie { color: var(--color-charlie); }
  .ui-bg-color-charlie { background-color: var(--color-charlie); }
  .ui-border-color-charlie { border-color: var(--color-charlie); }

  .ui-color-card { color: var(--color-card); }
  .ui-bg-color-card { background-color: var(--color-card); }
  .ui-border-color-card { border-color: var(--color-card); }
`;

export interface ApplicationThemeProviderProps {
  children: ReactNode;
}

const Provider: React.FC<ApplicationThemeProviderProps> = ({ children }) => {
  const { activeAppSize, activeThemeMode, activeThemeName } = useApplicationTheme();

  const themeColors = THEME_MODE_COLORS[activeThemeMode];

  const appTheme: ApplicationTheme = {
    ...themeColors.colors,
    currentThemeColor: THEME_COLORS[activeThemeName],
    activeAppSize,
    activeThemeMode,
  };

  const muiTheme = createTheme({
    palette: {
      mode: appTheme.activeThemeMode === 'light' ? 'light' : 'dark',
      background: { default: appTheme.bravo },
      text: { primary: appTheme.charlie },
      primary: { main: appTheme.currentThemeColor },
      error: { main: GLOBAL_COLORS.error },
      info: { main: appTheme.charlie },
      success: { main: GLOBAL_COLORS.success },
      warning: { main: GLOBAL_COLORS.warn },
    },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: parseInt(FONT_SIZES[activeAppSize].small, 10),
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'unset',
            minWidth: 'unset',
          },
        },
      },
    },
  });

  return (
    <StyledThemeProvider theme={appTheme}>
      <GlobalStyle />
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </StyledThemeProvider>
  );
};

export default Provider;
