import React, { FC } from 'react';
import { MainScreen } from 'components';
import { getTimeOfDay } from 'utils/helpers';
import HomePageWrapper from './home.styled';

interface HomePageProps {}

const HomePage: FC<HomePageProps> = () => {
  const currentTimeOfDay = getTimeOfDay();
  const placeholderTexts = {
    morning: "Good morning, I'm Sam.",
    afternoon: "Good afternoon, I'm Sam.",
    night: "Hey there, I'm Sam.",
  };

  return (
    <HomePageWrapper data-testid="home-page">
      <MainScreen
        title={placeholderTexts[currentTimeOfDay]}
        helmetTitle="Sam Darbeh (@sdarbeh) / Portfolio"
        description={
          <>
            <span className="ui-color-theme">I</span> develop exceptional web solutions that improve
            user experiences, drive business growth, and
            <span className="ui-color-theme"> solve real-world problems</span>.
          </>
        }
        hideLinks={['home']}
      />
    </HomePageWrapper>
  );
};

export default HomePage;
