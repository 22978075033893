import { MEDIA_BREAKPOINTS } from 'constants/application';
import styled, { keyframes } from 'styled-components';

const glideAnimation = keyframes`
  0% {
    border-left-width: 0;
    padding-left: calc(var(--item-size-7) + var(--item-size-2));
  }
  100% {
    border-left-width: var(--item-size-7);
  }
`;

const StyledContainer = styled.div`
  h1 {
    line-height: 1;
  }
  main {
    height: calc(100vh - (var(--item-size-5) * 2));
    width: 100%;

    @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
      width: 80%;
      max-width: 1200px;
      min-width: 750px;
    }
  }

  a {
    border-right: var(--item-size-5) solid transparent;

    &.active {
      color: var(--color-charlie);
      border-color: var(--color-theme);
    }

    &.active {
      font-weight: 600;
    }

    &:hover {
      border-color: var(--color-charlie);
    }

    @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
      align-self: flex-start;
      border-right: unset;
      border-left: var(--item-size-7) solid transparent;

      &.active {
        border-left-width: var(--item-size-7);
      }

      &:hover {
        animation: ${glideAnimation} 0.4s forwards;
      }
    }
  }
`;

export default StyledContainer;
