import { Modal } from '@mui/material';
import { MEDIA_BREAKPOINTS } from 'constants/application';
import styled from 'styled-components';

interface Props {
  type: 'center' | 'left' | 'right' | 'bottom' | 'sub';
}

export default styled(Modal)<Props>`
  .modal-container-content-center,
  .modal-container-content-sub {
    @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
      max-width: 700px;
    }
  }
  /* Center */
  .modal-container-content-center {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    overflow: hidden;
    width: max-content;
  }
  /* Horizontal modal */
  .modal-container-content-left,
  .modal-container-content-right {
    position: fixed;
    top: 0;
    padding: var(--item-size-5);
    height: calc(100% - (var(--item-size-5) * 2));
    width: max-content;
  }
  .modal-container-content-left {
    left: 0;
    right: unset;
  }
  .modal-container-content-right {
    left: unset;
    right: 0;
  }
  /* Bottom modal */
  .modal-container-content-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .bottom-modal-container {
    border-radius: var(--item-size-3) var(--item-size-3) 0 0;
    margin: 0 var(--item-size-4);
    width: 100%;
    height: max-content;
    max-width: 600px;
    overflow: hidden;
  }
  header {
    height: calc(var(--icon-xjumbo) * 1.25);
    border-bottom: 1px solid var(--color-card);
    svg {
      width: var(--icon-jumbo);
      height: var(--icon-jumbo);
    }
  }
  .bottom-modal-content {
    max-height: 55vh;
  }
  /* Sub modal */
  .modal-container-content-sub {
    position: relative;
    height: 100%;
    &,
    header {
      top: 0;
      left: 0;
    }
    header {
      position: fixed;
      width: calc(100% - (var(--item-size-5) * 2));
      padding: 0 var(--item-size-5);
    }
    @media (min-width: ${MEDIA_BREAKPOINTS.MEDIUM}) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      overflow: hidden;
      height: max-content;
      width: max-content;
    }
  }
  .sub-modal-container {
    width: 100%;
    height: 100%;
  }
  .sub-modal-content {
    padding-top: calc(var(--icon-xjumbo) * 1.25);
    height: calc(100% - var(--icon-xjumbo));
  }
`;
