import {
  APP_SIZE_ARRAY,
  AppSize,
  DEFAULT_APPLICATION_THEME_STATE,
  THEME_COLOR_ARRAY,
  THEME_MODE_ARRAY,
  ThemeColor,
  ThemeMode,
} from 'constants/application-theme';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { ApplicationThemeState, setApplicationTheme } from 'store/application-theme';

const typeToSetMap = {
  size: new Set(APP_SIZE_ARRAY),
  color: new Set(THEME_COLOR_ARRAY),
  mode: new Set(THEME_MODE_ARRAY),
};

/**
 * Custom React hook for accessing application theme-related state and actions.
 * @returns Object containing size, color, and mode properties.
 */
const Hook = () => {
  const reduxState: ApplicationThemeState = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();

  const cleaned = (type: 'size' | 'color' | 'mode') => {
    const state = reduxState[type as never];

    return typeToSetMap[type].has(state) ? state : DEFAULT_APPLICATION_THEME_STATE[type as never];
  };

  let activeThemeMode: ThemeMode;
  const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  if (reduxState.isSystemDefault) {
    activeThemeMode = isSystemDark ? 'dark' : 'light';
  } else {
    activeThemeMode = cleaned('mode');
  }

  return {
    activeAppSize: cleaned('size') as AppSize,
    setAppSize: (size: AppSize) => dispatch(setApplicationTheme.size(size)),
    activeThemeName: cleaned('color') as ThemeColor,
    setThemeColor: (color: ThemeColor) => {
      dispatch(setApplicationTheme.color(color));
    },
    activeThemeMode: activeThemeMode as ThemeMode,
    setThemeMode: (mode: ThemeMode) => dispatch(setApplicationTheme.mode(mode)),
    isSystemDefault: reduxState.isSystemDefault,
    setIsSystemDefault: (isDefault: boolean) => {
      dispatch(setApplicationTheme.mode(isSystemDark ? 'dark' : 'light'));
      return dispatch(setApplicationTheme.isSystemDefault(isDefault));
    },
  };
};

export default Hook;
