import React, { FC } from 'react';
import { ROUTE_NAME_MAP } from 'constants/routes';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MainScreenWrapper from './main-screen.styled';

export type MainScreenRouteName = 'home' | 'contact' | 'archive' | 'theme';

interface RouteItem {
  name: MainScreenRouteName;
  text: string;
  route: string;
  getState?: () => any;
}

const routeArray: RouteItem[] = [
  {
    name: 'home',
    text: 'Home',
    route: ROUTE_NAME_MAP.HOME_INDEX,
  },
  {
    name: 'contact',
    text: 'Get in Touch',
    route: ROUTE_NAME_MAP.CONTACT_INDEX,
  },
  {
    name: 'archive',
    text: 'Explore the Archive',
    route: ROUTE_NAME_MAP.ARCHIVE_INDEX,
  },
  {
    name: 'theme',
    text: 'Change Theme',
    route: ROUTE_NAME_MAP.modal('theme'),
    getState: () => ({ background: window.location.pathname }),
  },
];

export interface MainScreenProps {
  title?: string;
  helmetTitle?: string;
  description?: string | React.ReactNode;
  children?: React.ReactNode;
  hideLinks?: MainScreenRouteName[];
}

const MainScreen: FC<MainScreenProps> = ({
  title,
  helmetTitle,
  description,
  children,
  hideLinks,
}) => (
  <MainScreenWrapper
    className="ui-display-flex ui-items-center ui-justify-center main-screen-container"
    data-testid="main-screen"
  >
    <Helmet>
      <title>{helmetTitle || `${title} / Portfolio`}</title>
    </Helmet>
    <main className="p5 ui-display-flex ui-flex-column">
      <div className="pt5">
        <h1 className="ui-font-xjumbo mb5 ui-font-weight-bold" data-test-main-screen-header>
          {title}
        </h1>
        <h2 className="ui-font-jumbo ui-font-weight-thick" data-test-main-screen-description>
          {description}
        </h2>
      </div>
      <nav className="mt6 ui-display-flex ui-flex-column" data-test-main-screen-nav>
        {routeArray.map(
          ({ name, text, route, getState }) =>
            !hideLinks?.includes(name) && (
              <NavLink
                key={name}
                to={route}
                state={getState && getState()}
                className="mv2 ui-self-end ph2"
                rel="noopener noreferrer"
                aria-label={`Navigate to ${name}`}
                data-test-main-screen-nav-link={name}
              >
                <span>{text}</span>
              </NavLink>
            ),
        )}
      </nav>
      <div className="pv9">{children}</div>
    </main>
  </MainScreenWrapper>
);

export default MainScreen;
